@import './config';
@import "/var/www/html/app/Resources/assets/sass/utilities/_animation.scss";
@import "/var/www/html/app/Resources/assets/sass/utilities/_general.scss";
@import "/var/www/html/app/Resources/assets/sass/utilities/_layout.scss";
@import "/var/www/html/app/Resources/assets/sass/utilities/_type.scss";
@import "/var/www/html/app/Resources/assets/sass/utilities/_units.scss";


@include font-face('Trade Gothic Condensed', '/media/fonts/webfont-tglt-bold-cond', 700);
@include font-face('Trade Gothic New', '/media/fonts/webfont-tgnewlt-bold', 700);
